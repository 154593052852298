// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_rl d_bz";
export var storyRowWrapper = "w_hv d_hv d_bG";
export var storyLeftWrapper = "w_rm d_bw d_bL";
export var storyWrapperFull = "w_rn d_cz";
export var storyWrapperFullLeft = "w_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "w_mC d_hw";
export var storyLeftWrapperCenter = "w_rp d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "w_rq d_hC";
export var storyHeader = "w_rr d_hB d_s d_cp";
export var storyHeaderCenter = "w_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "w_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "w_rs d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "w_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "w_rg d_fc d_W";
export var imageWrapperFull = "w_rt d_s d_D d_bb d_W";