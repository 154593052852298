// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_sM";
export var lbContainerInner = "z_sN";
export var movingForwards = "z_sP";
export var movingForwardsOther = "z_sQ";
export var movingBackwards = "z_sR";
export var movingBackwardsOther = "z_sS";
export var lbImage = "z_sT";
export var lbOtherImage = "z_sV";
export var prevButton = "z_sW";
export var nextButton = "z_sX";
export var closing = "z_sY";
export var appear = "z_sZ";