// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "y_fM d_fM d_bw d_bD";
export var navbarDividedRight = "y_fN d_fN d_bw";
export var menuLeft = "y_sw d_fK d_bw d_ds d_bL";
export var menuRight = "y_sx d_fK d_bw d_ds d_bL";
export var menuCenter = "y_sy d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var menuDivided = "y_n8 d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var navbarItem = "y_n9 d_bt";
export var navbarLogoItemWrapper = "y_fV d_fV d_by d_bL";
export var burgerToggle = "y_sz d_f8 d_bv d_bp d_W";
export var burgerToggleOpen = "y_sB d_f8 d_bv d_bp d_0";
export var burgerInput = "y_sC d_fZ d_t d_bv d_X d_b8 d_dh d_bq";
export var burgerLine = "y_sD d_fY d_bv d_W d_t d_bj d_cl";
export var burgerMenuLeft = "y_sF d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuRight = "y_sG d_f4 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuCenter = "y_sH d_f5 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuDivided = "y_sJ d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var btnWrapper = "y_dZ d_cT d_cM d_bw d_bD d_s";
export var cancelBtn = "y_sK d_bY d_cz d_dx";
export var icon = "y_p0";
export var secondary = "y_sL d_by d_bL";