// extracted by mini-css-extract-plugin
export var alignLeft = "s_qc d_fl d_bC d_dr";
export var alignCenter = "s_bL d_fm d_bz d_ds";
export var alignRight = "s_qd d_fn d_bD d_dt";
export var menuContainer = "s_qX d_dS";
export var menuContainerFull = "s_qY d_dQ";
export var menuMainHeader = "s_jz d_jz d_s d_c0";
export var menuComponentWrapper = "s_jD d_jD d_cr";
export var menuComponentWrapperDesign2 = "s_jF d_jF d_cv";
export var menuComponentText = "s_jB d_jB d_ck";
export var menuComponentTextDesign2 = "s_jC d_jC d_cp";
export var menuImageWrapperDesign2 = "s_jG d_jG d_s d_W";