// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_qD d_gs d_cp";
export var heroHeaderCenter = "r_gt d_gt d_cp d_ds";
export var heroHeaderRight = "r_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "r_qF d_gp d_cs";
export var heroParagraphCenter = "r_gq d_gq d_cs d_ds";
export var heroParagraphRight = "r_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "r_qG d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "r_qH d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "r_qJ d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "r_qK d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "r_qL d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "r_qM x_qM";
export var heroExceptionNormal = "r_qN x_qN";
export var heroExceptionLarge = "r_qP x_qP";
export var Title1Small = "r_qQ x_qQ x_rv x_rw";
export var Title1Normal = "r_qR x_qR x_rv x_rx";
export var Title1Large = "r_qS x_qS x_rv x_ry";
export var BodySmall = "r_qT x_qT x_rv x_rP";
export var BodyNormal = "r_qV x_qV x_rv x_rQ";
export var BodyLarge = "r_qW x_qW x_rv x_rR";