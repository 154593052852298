// extracted by mini-css-extract-plugin
export var tileContent = "q_qn d_s d_D d_W";
export var teamTextLeft = "q_qp d_dr";
export var teamTextCenter = "q_qq d_ds";
export var teamTextRight = "q_qr d_dt";
export var alignLeft = "q_qc d_s d_bw d_fl d_bC d_dr";
export var alignCenter = "q_bL d_s d_bw d_fm d_bz d_ds";
export var alignRight = "q_qd d_s d_bw d_fn d_bD d_dt";
export var teamContainer = "q_qs d_dS";
export var teamContainerFull = "q_qt d_dQ";
export var teamRowWrapper = "q_qv d_b8";
export var teamTileWrapper = "q_j1 d_j1 d_W d_cr";
export var teamTileSquareWrapper = "q_qw d_j2 d_W d_cr";
export var teamTileRoundWrapper = "q_j2 d_j2 d_W d_cr";
export var teamTileNoGuttersWrapper = "q_j3 d_j3 d_W";
export var teamHoverNoGutters = "q_j4 d_j4 d_X d_s d_D d_bw d_bz d_bL d_bj";
export var teamImageText = "q_j9 d_j9 d_bh d_s d_bv";
export var teamInfoWrapperSquare = "q_qx d_j5";
export var teamInfoWrapperRound = "q_j5 d_j5";
export var teamInfoWrapper = "q_qy d_j6 d_X";
export var teamInfoWrapperNoGutters = "q_j7 d_j7 d_s d_D d_bz d_bJ d_bF d_c4";
export var teamImgWrapper = "q_jX d_jX";
export var teamImgWrapperAlt = "q_jY d_jY";
export var teamImgWrapperNoGutters = "q_j8 d_j8";
export var teamHeader = "q_qz d_cs";
export var teamHeaderAlt = "q_qB d_cs";
export var teamHeaderNoGutters = "q_qC d_cs d_cz";