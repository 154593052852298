// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "p_jN d_jN d_cr";
export var galleryMasonryImage = "p_jM d_jM d_s d_bN d_dx";
export var alignLeft = "p_qc d_fl d_bC d_dr";
export var alignCenter = "p_bL d_fm d_bz d_ds";
export var alignRight = "p_qd d_fn d_bD d_dt";
export var galleryContainer = "p_qf d_dS";
export var galleryContainerFull = "p_qg d_dQ";
export var galleryRowWrapper = "p_qh d_b8";
export var galleryGuttersImage = "p_jQ d_jQ d_G d_cr";
export var galleryNoGuttersImage = "p_jP d_jP d_G d_cz";
export var galleryTextGutters = "p_jK d_jK d_cs";
export var galleryTextNoGutters = "p_jL d_jL d_cs";
export var galleryTextMasonry = "p_qj d_jK d_cs";
export var galleryImageWrapper = "p_qk d_fc d_W";
export var descText = "p_ql d_jR d_X d_6 d_4 d_5 d_k";
export var guttersDesc = "p_qm";