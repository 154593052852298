// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "t_qZ d_gQ d_cs d_dr";
export var quoteParagraphCenter = "t_gR d_gR d_cs d_ds";
export var quoteParagraphRight = "t_gS d_gS d_cs d_dt";
export var quoteRowLeft = "t_q0 d_bC";
export var quoteRowCenter = "t_q1 d_bz";
export var quoteRowRight = "t_q2 d_bD";
export var quoteWrapper = "t_gN d_gN d_s d_cz d_bw d_bL d_bz";
export var quoteContentWrapper = "t_gP d_gP";
export var quoteExceptionSmall = "t_q3 x_q3";
export var quoteExceptionNormal = "t_q4 x_q4";
export var quoteExceptionLarge = "t_q5 x_q5";
export var quoteAuthorExceptionSmall = "t_q6 x_q6";
export var quoteAuthorExceptionNormal = "t_q7 x_q7";
export var quoteAuthorExceptionLarge = "t_q8 x_q8";